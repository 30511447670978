/** Commong JS scripts shared between pages */

const KEY_COOKIES_ACCEPTED = "cookies_accepted";
/* -- ON START -- */
document.addEventListener("DOMContentLoaded", function (event) {
  if (!getSetting(KEY_COOKIES_ACCEPTED, false)) {
    setTimeout(() => {
      document
        .getElementById("wrapper-dialog-cookies")
        .classList.add("visible");
    });
  }
  document
    .getElementById("button-close-dialog-cookies")
    .addEventListener("click", acceptCookies);
  document
    .querySelectorAll(".button-open-dialog-legal-notice")
    .forEach((el) => {
      el.addEventListener("click", () => {
        openDialog("#dialog-legal-notice");
      });
    });
  document
    .getElementById("button-close-dialog-legal-notice")
    .addEventListener("click", () => {
      closeDialog("#dialog-legal-notice");
    });
  document
    .getElementById("card-legal-notice-img")
    .addEventListener("click", () => {
      closeDialog("#dialog-legal-notice");
    });
  document
    .getElementById("card-legal-notice")
    .addEventListener("click", (event) => {
      event.stopPropagation();
    });
  const yearCopyright = document.getElementById("year");
  if (yearCopyright) {
    yearCopyright.innerText = new Date().getFullYear();
  }
  function gtag() {
    /*global dataLayer*/
    dataLayer.push(arguments);
  }
  if (process.env.NODE_ENV === "production") {
    // Or, `process.env.NODE_ENV !== 'production'`
    // Only runs in development and will be stripped from production build.
    window.dataLayer = window.dataLayer || [];
    console.log("g-analytics");
    gtag("js", new Date());
    gtag("config", "G-LQ81NRRHPY");
  }
});
/* -- SETTINGS -- */
const prefix = "sr_";
function setSetting(key, value) {
  localStorage.setItem(prefix + key, JSON.stringify(value));
}
function getSetting(key, defaultValue) {
  return JSON.parse(localStorage.getItem(prefix + key)) || defaultValue;
}
/* -- DIALOGS & MODALS -- */
const root = document.documentElement;
let scrollY;
function openDialog(selector) {
  const element = document.querySelector(selector);
  scrollY = root.scrollTop;
  root.style.width = "100%";
  root.style.position = "fixed";
  root.style.overflowY = "scroll";
  root.style.top = `${(-scrollY).toString()}px`;
  element.classList.add("visible");
}
function closeDialog(selector) {
  const element = document.querySelector(selector);
  root.style.removeProperty("width");
  root.style.removeProperty("position");
  root.style.removeProperty("overflow-y");
  root.style.removeProperty("top");
  root.style.scrollBehavior = "initial";
  window.scrollBy(0, scrollY);
  element.classList.remove("visible");
  // required: restore smooth scroll behavior as soon as possible after scrolling
  requestAnimationFrame(() => {
    root.style.removeProperty("scroll-behavior");
  });
}
function acceptCookies() {
  document.getElementById("wrapper-dialog-cookies").classList.remove("visible");
  setSetting(KEY_COOKIES_ACCEPTED, true);
}
/* -- SCROLLING -- */
let blocks = [];
let images = [];
window.addEventListener("load", () => {
  blocks = document.querySelectorAll(".scroller-block");
  images = document.querySelectorAll(".scroller-wrapper-img img");
  scrollHandler();
});
window.addEventListener("scroll", scrollHandler);
function scrollHandler() {
  let closestBlock = blocks[0];
  let shortestDistance = Infinity;
  blocks.forEach((block) => {
    const distance = Math.abs(
      root.scrollTop - block.offsetTop - block.scrollHeight,
    );
    if (distance < shortestDistance) {
      closestBlock = block;
      shortestDistance = distance;
    }
  });
  images.forEach((img) => {
    img.style.opacity = 0;
  });
  if (closestBlock) {
    const idImg = closestBlock.dataset["idImg"];
    const img = document.getElementById(idImg);
    if (img) {
      img.style.opacity = 1;
    }
  }
}
